import React from 'react';
import Fade from 'react-reveal/Fade';

const section3 = (props) => {
  return (
    <div id="section3">
      <div id="section3info">
        <Fade left>
          <div className="section3info" id="section3title">
            Mental Health
          </div>
          <div className="section3info" id="section3body">
            Shawn has always been a strong advocate for Mental Health, and this collection aims to highlight the struggle that many of us face on a daily basis.
          </div>
        </Fade>
      </div>
      <div id="eyecontainer">
        <Fade right>
          <div id="eyeimage" />
        </Fade>
      </div>
    </div>
  );
};

export default section3;
