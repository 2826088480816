/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import Countdown from 'react-countdown';
import axios from 'axios';
import abi from '../files/emotions.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x18E9eD5b323237f0CAF2c9D928F98c443a2bd4C5' : '0x5992205621996994307A07b6ac61F7711da8DE92';
let serverlessresponse;
const WEI_PER_ETH = '1000000000000000000';
const WEI_PER_TOK = '.07';

const customStyles = {
  content: {
    width: '270px',
    height: '250px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '10px solid #02e6e9',
  },
};

const mintbutton = (props) => {
  const [count, setCount] = useState(1);
  const [invalidcount, setInvalid] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalmessage, setMessage] = useState('');
  const [early, setEarly] = useState(false);
  const [saleState, setSS] = useState(1);
  const [remaining, setRemaining] = useState('');

  let contract;
  if (props.web3Provider) {
    try {
      contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        abi.abi,
        props.web3Provider.getSigner(),
      );
    } catch (err) {
      console.log(err);
      console.error('Error initializing contract');
    }
  }

  useEffect(async () => {
    try {
      serverlessresponse = await axios.post('https://emotionsnft.com/.netlify/functions/getstate');
      console.log(serverlessresponse);
      setSS(serverlessresponse.data.saleState);
      setRemaining(1777 - serverlessresponse.data.numAvailable);
    } catch (error) {
      console.log(error);
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const afterOpenModal = async () => {
    // references are now sync'd and can be accessed.
    if (!props.web3Provider) {
      setMessage('You must connect your wallet to the site in order to mint. Please use the button at the top of the page. Thank you!');
    } else if (saleState === 1) {
      let response;
      try {
        response = await contract.canClaimEarlyAccessToken(props.address.toLowerCase());
      } catch (err) {
        setMessage('Error connecting to contract. Are you on the correct ethereum network?');
        console.log(err);
        return;
      }
      if (!response) {
        setMessage('Our release is currently restricted to early access members only. Please check back on 11/7/21 to mint a Smile.');
      } else {
        setEarly(true);
        setMessage('Thank you for being an early access member! Right now you can only mint a single Smile, but check back on 11/7/21 to mint more!');
      }
    } else if (saleState === 2) {
      setEarly(true);
      setMessage('Thanks for being part of the Emotions project! You may mint up to 10 Smiles per transaction.');
    }
  };

  //   const getsupply = async () => {
  //     const supply = await contract.totalSupply();
  //     console.log(supply.toNumber());
  //     setCount(supply.toNumber());
  //   };

  //   const ownerMint = async () => {
  //     const response = await contract.ownerMint(1);
  //     console.log(response);
  //   };

  const mint = async () => {
    const helperScale = 1000000;
    const singlePriceWei = ethers.BigNumber.from(WEI_PER_ETH).mul(Math.round(WEI_PER_TOK * helperScale)).div(helperScale);
    const priceWei = singlePriceWei.mul(count);
    let estimatedGas;
    try {
      estimatedGas = await contract.estimateGas.mint(count, { value: priceWei });
    } catch (err) {
      alert('Error minting... Do you have enough funds in your wallet to cover the minting and gas fee?');
      return;
    }
    console.log(estimatedGas);
    const gasLimit = estimatedGas.add(50000); // Add 50,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    let response;
    try {
      response = await contract.mint(count, { value: priceWei, gasLimit });
    } catch (err) {
      alert('There was an issue minting.');
      return;
    }
    console.log(response);
  };

  const onCountChange = (event) => {
    if (saleState === 1) {
      if (parseInt(event.target.value) === 1) {
        setInvalid(false);
        setCount(parseInt(event.target.value));
      } else {
        setInvalid(true);
        if (Number.isNaN(parseInt(event.target.value))) {
          setCount(event.target.value);
        } else {
          setCount(parseInt(event.target.value));
        }
      }
    } else if (saleState === 2) {
      if (parseInt(event.target.value) <= 10 && parseInt(event.target.value) > 0) {
        setInvalid(false);
        setCount(parseInt(event.target.value));
      } else {
        setInvalid(true);
        if (Number.isNaN(parseInt(event.target.value))) {
          setCount(event.target.value);
        } else {
          setCount(parseInt(event.target.value));
        }
      }
    }
  };

  const incrementCount = () => {
    if (Number.isNaN(parseInt(count))) {
      setCount(1);
      setInvalid(false);
    } else if (saleState === 2 && count < 10) {
      if ((count + 1) > 0) {
        setInvalid(false);
      }
      setCount((count + 1));
    } else if (saleState === 1 && count < 1) {
      if ((count + 1) > 0) {
        setInvalid(false);
      }
      setCount((count + 1));
    }
  };

  const decrementCount = () => {
    if (Number.isNaN(parseInt(count))) {
      setCount(1);
      setInvalid(false);
    } else if (count > 1) {
      if (saleState === 1 && (count - 1) === 1) {
        setInvalid(false);
      }
      if (saleState === 2 && (count - 1) <= 10) {
        setInvalid(false);
      }
      setCount((count - 1));
    }
  };

  const renderer = ({
    days, hours, minutes, seconds,
  }) => {
    return <span id="time">{days}d {hours}h {minutes}m {seconds}s</span>;
  };

  return (
    <div id="mintwrapper">
      {(saleState === 0) ? (
        <div id="countdowncontainer">
          <div id="countdowntext">Minting For Whitelist Members Opens In:</div>
          <Countdown date="2021-11-06T18:00:00" renderer={renderer} />
        </div>
      ) : ((saleState === 1) ? (
        <div id="countdowncontainer">
          <div role="button" id="mintmodalopen" tabIndex="0" onClick={openModal}>
            Mint a Smile
          </div>
          <div id="countdowntext">Open Minting Begins In:</div>
          <Countdown date="2021-11-07T18:00:00" renderer={renderer} />
          <a id="openseabutton" href="https://opensea.io/collection/emotions-shawn-coss/" target="_blank" rel="noopener noreferrer">View on opensea <span id="opensealogo" /></a>
        </div>
      ) : (
        <div id="countdowncontainer">
          <div role="button" id="mintmodalopen" tabIndex="0" onClick={openModal}>
            Mint a Smile
          </div>
          <div id="countdowntext">{remaining} out of 1777 Smiles have been Minted</div>
        </div>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div id="modalcontainer">
          <div id="modalclose" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </div>
          <div id="modalmessagetext">
            {modalmessage}
          </div>
          <input id="mintinput" onChange={onCountChange} value={count} />
          <div className="countbutton" id="plus" onClick={incrementCount}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </div>
          <div className="countbutton" id="minus" onClick={decrementCount}>
            <FontAwesomeIcon icon={faMinus} size="lg" />
          </div>
          <Fade bottom collapse when={invalidcount}>
            <div className="invalid-feedback"
            // Boostrap 4 uses some CSS tricks to simplify
            // error handling but we're doing it differently
            // so the next line disables these tricks
              style={{ display: 'block' }}
            >
              Invalid Quantity
            </div>
          </Fade>
          {(!invalidcount && early) ? (
            <div role="button" id="mintreal" tabIndex={-1} onClick={mint} className="mintbutton">
              Mint: .07 ETH + gas
            </div>
          ) : (
            <div id="fakemint" className="mintbutton">
              Mint: .07 ETH + gas
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(mintbutton);
