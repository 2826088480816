/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Nav from '../components/nav';
import Section1 from '../components/section1';
import Section2 from '../components/section2';
import Section3 from '../components/section3';
import Section4 from '../components/section4';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <Nav />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  );
};

export default Welcome;
