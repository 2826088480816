import React from 'react';
import Fade from 'react-reveal/Fade';
import MintButton from './mintbutton';

const section1 = (props) => {
  return (
    <div id="section1">
      <div id="section1contentwrapper">
        <Fade left cascade>
          <div id="section1text">
            <p className="section1text" id="section1title">Emotions</p>
            <p className="section1text" id="section1subtitle">An NFT Collection by Shawn Coss</p>
            <p className="section1text" id="section1body">The Emotions collection is the first ever NFT drop from renowned artist Shawn Coss.
              Each of the pieces are unique and generated from handcrafted art.
            </p>
          </div>
        </Fade>
        <Fade right>
          <div id="section1images">
            <div className="imagerow">
              <div className="facepic" id="face1" />
              <div className="facepic" id="face2" />
            </div>
            <div className="imagerow">
              <div className="facepic" id="face3" />
              <div className="facepic" id="face4" />
            </div>
          </div>
        </Fade>
      </div>
      <MintButton />
    </div>
  );
};

export default section1;
