import React from 'react';
import './style.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Welcome from './screens/welcome';

const FallBack = (props) => {
  return <div>URL Not Found</div>;
};

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route component={FallBack} />
      </Switch>
    </Router>
  );
};

export default App;
