import React from 'react';

const section3 = (props) => {
  return (
    <div id="section4">
      Copyright © 2021 Crypto Technologies. All rights reserved.
    </div>
  );
};

export default section3;
