import React from 'react';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord,
} from '@fortawesome/free-brands-svg-icons';

const section2 = (props) => {
  return (
    <div id="section2">
      <div id="cosscontainer">
        <Fade left>
          <div id="cossimage" />
        </Fade>
      </div>
      <div id="section2info">
        <Fade right>
          <div className="section2info" id="section2title">
            Shawn Coss / Death Artist
          </div>
          <div className="section2info" id="section2body">
            Shawn Coss is an Artist, advocate for mental health, and co-owner of Any Means Necessary clothing. He has been producing digital art for 10 years.
          </div>
          <div className="section2info" id="section2socials">
            <a href="https://twitter.com/ShawnCoss" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="3x" />
            </a>
            <a href="https://www.instagram.com/shawncoss/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </a>
            <a href="https://t.co/WPaGJveXhe?amp=1" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="3x" />
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default section2;
